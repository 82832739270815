.fbutton {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.money_container {
  align-items: flex-end;
  display: flex;
  /* text-align: center; */
  justify-content: center;
}

.money {
  color: var(--primary);
  text-align: center;
  font-size: 18px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.money > span,
p {
  font-weight: 900;
}

.topcard {
  background: linear-gradient(179deg, #fef2f2, transparent);
  margin-bottom: 30px;
}

.topcard__header {
  font-size: 16px;
  font-weight: 800;
  font-family: var(--secondary-font);
  color: var(--primary);
}

.topcard__header1 {
  font-size: 16px;
  font-weight: 800;
  font-family: var(--secondary-font);
  color: var(--secondary);
}

.topcard__answer {
  font-weight: 500;
  color: var(--font-secondary);
}

.topcard__theader {
  font-weight: 500;
  font-size: 16px;
  color: var(--font-secondary);
}

.accordion__header {
  font-size: 20px;
  font-weight: 800;
  font-family: var(--secondary-font);
  color: var(--secondary);
}

.accordion2__header {
  color: var(--primary);
  font-size: 20px;
  font-weight: 800;
  font-family: var(--secondary-font);
}

.content__header {
  font-weight: 800;
  color: var(--secondary);
}

.content__answer {
  color: var(--secondary);
  font-weight: 500 !important;
}

.seemore {
  color: black;
  font-weight: 700;
}
