.cookie_banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(254, 254, 254, 0.8);
    color: black;
    padding: 10px;
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
    width: 100%;
}

.cookie_text {
    flex: 1;
    margin-right: 20px;
}

.cookie_text p {
    margin: 0;
}

.cookie_text a {
    color: #fff;
}

.cookie_buttons {
    display: flex;
    justify-content: flex-end;
    border-radius: 5px;
    font-weight: bold;
}

.settings {
    width: 200px;
    border-radius: 5px;
    background-color: #EE2E22;
    color: #fff;
}

.settings:hover {
    background-color: #9FC031;
}

.accept {
    width: 260px;
    border-radius: 5px;
    background-color: #EE2E22;
    color: #fff;
}

.accept:hover {
    background-color: #9FC031;
}

.reject {
    width: 260px;
    border-radius: 5px;
    background-color: #EE2E22;
    color: #fff;
}

.reject:hover {
    background-color: #9FC031;
}

.save {
    width: 143px;
    height:33px;
    border-radius: 5px;
    background-color: #EE2E22;
    color: #fff;
}

.save:hover {
    background-color: #9FC031;
}

/* Media query for mobile screens */
@media only screen and (max-width: 768px) {
    .settings {
            width: 260px;
            border-radius: 5px;
            background-color: #EE2E22;
            color: #fff;
        }

}