.h1Grey {
  font-family: var(--primary-font);
  font-size: 2em;
  color: var(--secondary);
  font-weight: 800;
}

.h2Grey {
  font-family: var(--primary-font);
  font-size: 1.5em;
  color: var(--secondary);
  font-weight: 800;
}

.h3Grey {
  font-family: var(--primary-font);
  font-size: 1.17em;
  color: var(--secondary);
  font-weight: 800;
}

.h4Grey {
  font-family: var(--primary-font);
  font-size: 1em;
  color: var(--secondary);
  font-weight: 800;
}

.h5Grey {
  font-family: var(--primary-font);
  font-size: 0.83em;
  color: var(--secondary);
  font-weight: 800;
}

.h6Grey {
  font-family: var(--primary-font);
  font-size: 0.75em;
  color: var(--secondary);
  font-weight: 800;
}

.h1Red {
  color: var(--primary);
  font-size: 2em;
  font-weight: 800;
  font-family: var(--primary-font);
}

.h2Red {
  color: var(--primary);
  font-size: 1.5em;
  font-weight: 700;
  font-family: var(--secondary-font);
}

.h3Red {
  color: var(--primary);
  font-size: 1.17em;
  font-weight: 700;
  font-family: var(--secondary-font);
}

.h4Red {
  color: var(--primary);
  font-size: 1em;
  font-weight: 700;
  font-family: var(--secondary-font);
}
.h5Red {
  color: var(--primary);
  font-size: 0.83em;
  font-weight: 700;
  font-family: var(--secondary-font);
}
.h6Red {
  color: var(--primary);
  font-size: 0.75em;
  font-weight: 700;
  font-family: var(--secondary-font);
}

.blackArrText {
  color: black;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 800;
}

.href {
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.href:hover {
  color: var(--primary);
}
