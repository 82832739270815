@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary: #DD302A;
  --secondary: #5a727a;
  --tertiary: #f8f4f4;
  --grad-prom: #da291c;
  --grad-sec: #f0f2f8;
  --primary-font: "Nunito";
  --secondary-font: "Nunito Sans";
  --tertiary-font: "Rubik";
  --fallback-font: sans-serif;
  --font-primary: #242529;
  --font-secondary: #5a727a;
  --font-tertiary: #6414af;
  --font-size-h1: 38px;
  --font-size-h2: 30px;
  --font-size-h3: 28px;
}

body,
html {
  font-family: "Nunito" !important;
  height: 100%;
}

.main__container {
  background: #f9f9f9;
  min-height: 100vh;
  position: relative;
}

.app__header__one {
  color: #353f4e;
  font-size: var(--font-size-h1);
  font-weight: 800;
}

.app__header__two {
  color: var(--primary);
  font-size: var(--font-size-h2);
  font-weight: bold;
}

.app__header__three {
  color: var(--primary);
  font-size: (--font-size-h3);
  font-weight: bold;
}

/* .footer_exclude {
  margin-bottom: 320px;
}

.footer_exclude_xs {
  margin-bottom: 620px;
} */
