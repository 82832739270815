.body__btn__hover:hover {
    background: #dd302a !important;
  }
  

  .button__header{
    justify-content: center;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    align-items: center;
  }