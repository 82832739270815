@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
:root {
  --primary: #DD302A;
  --secondary: #5a727a;
  --tertiary: #f8f4f4;
  --grad-prom: #da291c;
  --grad-sec: #f0f2f8;
  --primary-font: "Nunito";
  --secondary-font: "Nunito Sans";
  --tertiary-font: "Rubik";
  --fallback-font: sans-serif;
  --font-primary: #242529;
  --font-secondary: #5a727a;
  --font-tertiary: #6414af;
  --font-size-h1: 38px;
  --font-size-h2: 30px;
  --font-size-h3: 28px;
}

body,
html {
  font-family: "Nunito" !important;
  height: 100%;
}

.main__container {
  background: #f9f9f9;
  min-height: 100vh;
  position: relative;
}

.app__header__one {
  color: #353f4e;
  font-size: 38px;
  font-size: var(--font-size-h1);
  font-weight: 800;
}

.app__header__two {
  color: #DD302A;
  color: var(--primary);
  font-size: 30px;
  font-size: var(--font-size-h2);
  font-weight: bold;
}

.app__header__three {
  color: #DD302A;
  color: var(--primary);
  font-size: (--font-size-h3);
  font-weight: bold;
}

/* .footer_exclude {
  margin-bottom: 320px;
}

.footer_exclude_xs {
  margin-bottom: 620px;
} */

/* <------ Nav links Override styles ------> */

.nav-link.active {
  color: #DD302A !important;
}

p {
  margin: 0px !important;
}

button {
  border: none;
}
select {
  text-align: left;
  font: normal normal bold 16px/29px Nunito !important;
  letter-spacing: 0px;
  color: #5a727a;
}

input:-ms-input-placeholder {
  opacity: 0.5 !important;
}

input::placeholder {
  opacity: 0.5 !important;
}

.home-container{
  background-image: url(/static/media/pattern.2e3e02fc.png);
  background-size: 100%;
  background-color: #DA291C;
}

.nav-link {
  font-family: var(--primary-font);
  font-weight: 800;
  font-size: 1.2rem;
}

.nav-link:hover {
  color: var(--primary) !important;
}

.error-input {
  border: 1px solid rgb(218 41 28 / 25%) !important;
  box-shadow: 0 0 0 0.25rem rgb(218 41 28 / 25%) !important;
}

.errors {
  color: #da291c;
  font-size: 0.8rem;
}

/* <------- Divider ------> */

.divider {
  background-color: #d0d0d0;
  height: 2px;
}

/* <----- Form label override style ----->  */

.form-label {
  color: #5a727a;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
}
.dashed {
  padding-left: 10px !important;
}

ul.dashed {
  list-style-type: "-";
}

ul.dashed > li {
  padding: 0px 0px 0px 0px;
  margin-left: 10px;
}

.t_c_text {
  color: #da291c;
  font-weight: 600;
}

/* <----- Stepper override style ----->  */

.MuiStepIcon-root.Mui-completed,
.MuiStepIcon-root.Mui-active,
.MuiStepLabel-label.Mui-completed {
  color: #da291c !important;
}

.MuiStepLabel-label {
  font: normal normal normal 14px/26px Nunito !important;
  letter-spacing: 0.11px;
  color: #5a727a;
  opacity: 1;
}

.textarea-label {
  color: #5a727a;
  height: 30px !important;
  margin-top: 10px;
}

.MuiFormControlLabel-label {
  text-align: left;
  font: normal normal normal 16px/29px Nunito !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.8;
}

/* <------- Header Styles --------> */

.header__title {
  text-align: left;
  font: normal normal bold 16px/25px Nunito;
  letter-spacing: 0px;
  color: #da291c !important;
  line-height: 30px !important;
  opacity: 1;
}

.header__sub {
  text-align: left;
  font: normal normal 800 16px/29px Nunito;
  letter-spacing: 0px;
  color: #6e6f6c;
  opacity: 1;
}

.description_placeholders {
  text-align: left;
  font: normal normal normal 16px/24px Nunito;
  letter-spacing: 0px;
  color: #6e6f6c;
  opacity: 1;
}

.list > li {
  text-align: left;
  font: normal normal bold 16px/26px Nunito;
  letter-spacing: 0.16px;
  color: #5a727a;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 3em;
  border-width: 2px;
  height: 3em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid rgb(218, 41, 28);
  border-right: 0.5em solid rgb(218, 41, 28);
  border-bottom: 0.5em solid rgb(218, 41, 28);
  border-left: 0.5em solid #ffffff;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.gif-loader {
  width: 100%;
  height: 100%;
  margin: auto;
  align-items: center;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
}

.gif-loader-full {
  width: 100%;
  height: 90vh;
  margin: auto;
  align-items: center;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
}

/* --------Homepage----------------------- */

.homepage1__heading1 {
  line-height: 1.2;
  color: white;
}

.homepage1__heading2 {
  color: white;
}

.homepage__main__text {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.card__header {
  color: white;
  font-size: var(--font-size-h2);
  font-weight: 800;
  display: flex;
  align-items: center;
  /* width: 70% !important; */
}

.card__header__container {
  background: var(--secondary);
  border-radius: 10px;
  align-items: center;
  padding: 5px;
  /* margin-right: 30px; */
}

.card_header_img {
  width: 100px;
  padding: 5px 10px;
  /* border-radius: 10px;
  background: white; */
  text-align: center;
}

.card__homepage {
  width: 100% !important;
  border-radius: 10px;
}

.card__list__header {
  color: var(--primary);
  font-weight: 700;
}

.card__list {
  color: var(--font-secondary);
  font-size: 14px;
  border-bottom: 1px solid #d2d1d1;
  padding-bottom: 20px;
  padding-left: 15px;
}

.card__list2 {
  padding-bottom: 0px;
}

.card__list2 > li {
  margin-bottom: 20px;
}

.card__list > li > span:first-child {
  font-weight: 600;
}

.card__list > li > div:first-child {
  font-weight: 600;
}

.card__footer {
  color: var(--font-secondary);
}

.card__footer > span:first-child {
  font-weight: 700;
}

.card__footer > span:last-child {
  font-weight: 700;
  color: var(--font-primary);
  cursor: pointer;
}

.card__footer > span:last-child:hover {
  color: var(--primary);
}

.card__footer__bottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--primary);
}

.amount {
  font-size: 1.4rem;
}

.amount > span:last-child {
  color: var(--primary);
  font-weight: 800;
}

.card__avatar__text > div:first-child {
  font-size: 14px;
  font-weight: 700;
  color: var(--secondary);
}

.card__avatar__img {
  width: 170px;
}

.Mui-error{
  border: #DA291C !important;
}

.card__avatar__text > div:last-child {
  font-size: 14px;
  color: var(--secondary);
}

.card__list2__un {
  list-style-type: none;
  border: none;
}
.bullet {
  -webkit-text-decoration: dotted;
          text-decoration: dotted;
}

.card__footer2 {
  color: var(--font-secondary);
  text-align: left;
}

.card__footer2 > span {
  font-weight: 700;
  color: var(--font-primary);
  cursor: pointer;
}

.card__footer2 > span:last-child:hover {
  color: var(--primary);
}

.font-weight-bold {
  font-weight: bold;
}

/* -----------------Content------------ */

.content {
  color: var(--secondary);
}

/* ------------------Breadcrumb------------------ */
.breadcrumbs {
  padding-top: 15px;
}

.breadcrumbs > a {
  color: var(--font-secondary);
  text-decoration: none;
}

.breadcrumbs > a > svg {
  font-size: 14px;
}

/* ----------Accordion------------ */
.MuiAccordionSummary-root.Mui-expanded {
  background-color: var(--secondary) !important;
  min-height: auto !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0px !important;
}

.MuiAccordionSummary-root {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background: white !important;
}

.Mui-expanded > svg {
  background-color: var(--secondary) !important;
  color: white !important;
}

.Mui-expanded > div > p {
  color: white !important;
}

.MuiAccordionDetails-root {
  background: #f9f9f9;
  color: var(--font-secondary);
}

.boxBorder{
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid #D0D0D0;
  border-radius: 10px;
  min-height: 200px;
  margin-top: 50px;
  padding: 30px;
}

.contact_form_input input, textarea{
  height: 50px;
}

.contact_form_input label{
  font-weight: 700 !important; 
  color: #5A727A;
}

.boxBorder h4, h6{
  color: #5A727A;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
}

.gif-loader-fullscreen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  margin: auto;
  align-items: center;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
}

.lds-dual-ring {
  display: inline-block;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  margin: 5px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.footer_logo_lg {
  width: 160px;
  margin-bottom: 5px;
  transform: translateX(-5px);
}

/* Keyframe animation for loader */
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* -------------Footer---------------- */
.footer_logo {
  max-width: 250px;
  margin-bottom: 20px;
}

/* -----------------contact-------------- */
.contact_submit {
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #DA291C !important;
  color: white;
  font-size: 18px;
  width: 150px;
  font-weight: 900;
  box-shadow: 2px 2px 8px #6d6565;
  margin-top: 20px;
}

.main__img {
  width: 70%;
}

.stats-img {
  width: 100%;
}

.app__header__two__sub {
  color: #5a727a;
}

.tertiary_logo {
  width: 130px;
  /* height: 54.06px; */
  height: auto;
}

.secondary_logo {
  height: auto;
  width: 160px;
}

@media  only screen and (min-width: 1000px) and (max-width: 1400px) {
  .main__img{
    transform: translateX(10%);
    height: 100%;
  }
}

/* Media query for mobile screens */
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  /* changing homepage image width */
  .main__img {
    width: 160px;
  }

  /* Changing width of card in homepage */
  .card__homepage {
    width: 100% !important;
  }
  /* Changing width of card header */
  /* 
  .card__header {
    width: 100% !important;
  }
  */

  .home-container{
    background-size: 400%;
  }

  /* Font-size changed selecting the first row */
  .homepage__main__text > .row:first-child {
    font-size: 26px;
    text-align: center;
  }

  .homepage1__heading2 {
    text-align: center;
  }

  .app__header__two {
    text-align: center;
    font-size: 26px;
  }

  .app__header__two__sub {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }

  /* displaying footer on mobile screen */
  .card__footer__bottom {
    display: block;
  }

  /* Changing the width of the card footer */
  .card__footer__bottom > div:last-child {
    width: auto;
  }

  /* Changing card image */
  .card__avatar__img {
    width: 130px;
  }

  .card__list__header {
    text-align: center;
  }

  .card__list2__un {
    font-size: 16px;
  }

  .boxBorder{
    padding: 10px;
  }
  /* changing font size of amount */
  .amount {
    font-size: 1rem;
    margin-right: 10px;
  }

  .card__footer2{
    text-align: center;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  /* Changing card image */
  .card__avatar__img {
    width: 170px;
  }
}

.Button_body__btn__hover__1PNwq:hover {
    background: #dd302a !important;
  }
  

  .Button_button__header__30NYr{
    justify-content: center;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    align-items: center;
  }
.Navbar_logo__1H3m4 {
  height: auto;
  width: 100px;
}

.Navbar_topnavbar__2ntQN {
  background: #5a727a;
}

.Navbar_topnavbar_row__1QZe2 {
  margin: auto;
  color: white;
}

.Navbar_appbar1__content__1T_E9{
  min-width: 300px !important;
}

.Navbar_appbar1__content__1T_E9 > a {
  color: white;
  margin-left: 2rem;
}

.Navbar_footer_container__34FYT {
  background: #5a727a;
  padding: 30px;
  color: white;
  margin-top: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  height: auto;
  /* position: fixed;
  bottom: 0; */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.Navbar_footer_container_xs__-FXMx {
  background: #5a727a;
  padding: 30px;
  color: white;
  margin-top: 20px;
  height: 590px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.Navbar_footer_header__3JNXK {
  font-weight: 700;
}

.Navbar_footer_social__svpiG {
  color: white;
}

.Navbar_header_navs__WtZk_ {
  /* display: flex; */
  margin-right: 3rem;
  justify-content: space-between;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .Navbar_header_navs__WtZk_ {
    width: 50%;
  }
}

.Heading_h1Grey__2coWx {
  font-family: var(--primary-font);
  font-size: 2em;
  color: var(--secondary);
  font-weight: 800;
}

.Heading_h2Grey__2isCg {
  font-family: var(--primary-font);
  font-size: 1.5em;
  color: var(--secondary);
  font-weight: 800;
}

.Heading_h3Grey__2fwL5 {
  font-family: var(--primary-font);
  font-size: 1.17em;
  color: var(--secondary);
  font-weight: 800;
}

.Heading_h4Grey___XZvq {
  font-family: var(--primary-font);
  font-size: 1em;
  color: var(--secondary);
  font-weight: 800;
}

.Heading_h5Grey__1oGH4 {
  font-family: var(--primary-font);
  font-size: 0.83em;
  color: var(--secondary);
  font-weight: 800;
}

.Heading_h6Grey__2GHu- {
  font-family: var(--primary-font);
  font-size: 0.75em;
  color: var(--secondary);
  font-weight: 800;
}

.Heading_h1Red__tBvTR {
  color: var(--primary);
  font-size: 2em;
  font-weight: 800;
  font-family: var(--primary-font);
}

.Heading_h2Red__3bPNK {
  color: var(--primary);
  font-size: 1.5em;
  font-weight: 700;
  font-family: var(--secondary-font);
}

.Heading_h3Red__1YAHH {
  color: var(--primary);
  font-size: 1.17em;
  font-weight: 700;
  font-family: var(--secondary-font);
}

.Heading_h4Red__1vRX4 {
  color: var(--primary);
  font-size: 1em;
  font-weight: 700;
  font-family: var(--secondary-font);
}
.Heading_h5Red__1o_AU {
  color: var(--primary);
  font-size: 0.83em;
  font-weight: 700;
  font-family: var(--secondary-font);
}
.Heading_h6Red__23ofV {
  color: var(--primary);
  font-size: 0.75em;
  font-weight: 700;
  font-family: var(--secondary-font);
}

.Heading_blackArrText__1fO4- {
  color: black;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 800;
}

.Heading_href__x_59g {
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.Heading_href__x_59g:hover {
  color: var(--primary);
}

.cookies_cookie_banner__2rUcl {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(254, 254, 254, 0.8);
    color: black;
    padding: 10px;
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
    width: 100%;
}

.cookies_cookie_text__3apyq {
    flex: 1 1;
    margin-right: 20px;
}

.cookies_cookie_text__3apyq p {
    margin: 0;
}

.cookies_cookie_text__3apyq a {
    color: #fff;
}

.cookies_cookie_buttons__1M3tZ {
    display: flex;
    justify-content: flex-end;
    border-radius: 5px;
    font-weight: bold;
}

.cookies_settings__1vgTQ {
    width: 200px;
    border-radius: 5px;
    background-color: #EE2E22;
    color: #fff;
}

.cookies_settings__1vgTQ:hover {
    background-color: #9FC031;
}

.cookies_accept__3tTdO {
    width: 260px;
    border-radius: 5px;
    background-color: #EE2E22;
    color: #fff;
}

.cookies_accept__3tTdO:hover {
    background-color: #9FC031;
}

.cookies_reject__1ZhGo {
    width: 260px;
    border-radius: 5px;
    background-color: #EE2E22;
    color: #fff;
}

.cookies_reject__1ZhGo:hover {
    background-color: #9FC031;
}

.cookies_save__2TlnA {
    width: 143px;
    height:33px;
    border-radius: 5px;
    background-color: #EE2E22;
    color: #fff;
}

.cookies_save__2TlnA:hover {
    background-color: #9FC031;
}

/* Media query for mobile screens */
@media only screen and (max-width: 768px) {
    .cookies_settings__1vgTQ {
            width: 260px;
            border-radius: 5px;
            background-color: #EE2E22;
            color: #fff;
        }

}
.fastfacts_fbutton__LqCh- {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.fastfacts_money_container__3nzr3 {
  align-items: flex-end;
  display: flex;
  /* text-align: center; */
  justify-content: center;
}

.fastfacts_money__1r6pG {
  color: var(--primary);
  text-align: center;
  font-size: 18px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.fastfacts_money__1r6pG > span,
p {
  font-weight: 900;
}

.fastfacts_topcard__Zg3gC {
  background: linear-gradient(179deg, #fef2f2, transparent);
  margin-bottom: 30px;
}

.fastfacts_topcard__header__6JYtn {
  font-size: 16px;
  font-weight: 800;
  font-family: var(--secondary-font);
  color: var(--primary);
}

.fastfacts_topcard__header1___Fbqc {
  font-size: 16px;
  font-weight: 800;
  font-family: var(--secondary-font);
  color: var(--secondary);
}

.fastfacts_topcard__answer__2TLKU {
  font-weight: 500;
  color: var(--font-secondary);
}

.fastfacts_topcard__theader__24o7w {
  font-weight: 500;
  font-size: 16px;
  color: var(--font-secondary);
}

.fastfacts_accordion__header__3S0SU {
  font-size: 20px;
  font-weight: 800;
  font-family: var(--secondary-font);
  color: var(--secondary);
}

.fastfacts_accordion2__header__1RuKd {
  color: var(--primary);
  font-size: 20px;
  font-weight: 800;
  font-family: var(--secondary-font);
}

.fastfacts_content__header__3w6vi {
  font-weight: 800;
  color: var(--secondary);
}

.fastfacts_content__answer__2KT81 {
  color: var(--secondary);
  font-weight: 500 !important;
}

.fastfacts_seemore__1WL-c {
  color: black;
  font-weight: 700;
}

.accordion_main__3gVLa {
  border-radius: 10px !important;
  box-shadow: none !important;
  background: #f9f9f9 !important;
}

.accordion_title__vTg9W {
  border-radius: 10px !important;
}

.accordion_title__vTg9W > div > p {
  font-weight: 600;
  color: var(--secondary);
}

.Form_header__97rZ7{
    text-align: center;
    font: normal normal 800 30px/29px Nunito;
    letter-spacing: 0px;
    font-family: 'Nunito', sans-serif;
    color: #6E6F6C;
    opacity: 1;
}

.Form_boxBorder__1p3eT{
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    min-height: 200px;
    margin-top: 50px;
    padding: 30px;
}

.Form_boxBorder__1p3eT h4, h6{
    color: #5A727A;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
}

@media screen and (max-width: 768px) {
    .Form_boxBorder__1p3eT{
        padding: 10px;
    }
}
.ProductCard_container__2Uhto{
    width: 550px;
    min-height: 398px;
    margin: 20px 20px 0px 20px;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    padding: 30px;
}

.ProductCard_title__1IouT{
    text-align: left;
    font: normal normal 900 26px/29px Nunito;
    letter-spacing: 0px;
    color: #5A727A;
    opacity: 1;
}

.ProductCard_sub__v7YdM{
    text-align: left;
    font: normal normal 800 16px/29px Nunito;
    letter-spacing: 0px;
    color: #5A727A;
    opacity: 1;
}

.ProductCard_price__2K6oq{
    text-align: left;
    font: normal normal 900 22px/29px Nunito;
    letter-spacing: 0px;
    color: #DA291C;
    opacity: 1;
}

.ProductCard_cover__Nu3EY{
    text-align: left;
    font: normal normal 800 16px Nunito;
    letter-spacing: 0px;
    margin-top: 20px !important;
    color: #DA291C;
    opacity: 1;
}

.ProductCard_excess__2VxVn{
    text-align: left;
    font: normal normal bold 16px Nunito;
    letter-spacing: 0px;
    color: #5A727A;
    opacity: 1;
    margin-bottom: 20px !important;
}

.ProductCard_turnover__3vWdF{
    text-align: left;
    font: normal normal bold 16px Nunito;
    letter-spacing: 0px;
    color: #5A727A;
    opacity: 1;
}

.ProductCard_description__2yq6t{
    text-align: left;
    font: normal normal normal 16px/24px Nunito;
    letter-spacing: 0px;
    color: #5A727A;
    opacity: 1;
    margin-top: 20px !important;
}


.ProductCard_description_1__27WJZ{
    text-align: left;
    font: normal normal normal 16px/24px Nunito;
    letter-spacing: 0px;
    color: #5A727A;
    opacity: 1;
}

@media screen and (max-width: 430px) {
    .ProductCard_container__2Uhto{
        width: 100%;
        margin: 0px;
        padding: 15px;
    }
}


/*  <-------------- SUMMARY CARD ----------------> */

.ProductCard_policyPrimaryContainer__3ZjAQ{
    width: 100% ;
}

.ProductCard_policyPrimary__1TF3r{
    text-align: left;
    font: normal normal 900 22px/29px Nunito;
    letter-spacing: 0px;
    font-size: 1.05rem;
    color: #DA291C;
    opacity: 1;
}

.ProductCard_policySecondaryContainer__1X2oZ {
    width: 100%;
}

.ProductCard_spanText__3NUCA{
    font-size: 0.8rem;
    transform: translateY(-30%);
    font-weight: 500;
}

.ProductCard_policySecondary__L6EOL {
    text-align: left;
    font: normal normal bold 16px Nunito;
    letter-spacing: 0px;
    color: #5A727A;
    opacity: 1;
    font-size: 1.05rem;
}

.ProductCard_boxBorder__1bRHu{
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    min-height: 200px;
    margin-top: 50px;
    padding: 30px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

