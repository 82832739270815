.header{
    text-align: center;
    font: normal normal 800 30px/29px Nunito;
    letter-spacing: 0px;
    font-family: 'Nunito', sans-serif;
    color: #6E6F6C;
    opacity: 1;
}

.boxBorder{
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    min-height: 200px;
    margin-top: 50px;
    padding: 30px;
}

.boxBorder h4, h6{
    color: #5A727A;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
}

@media screen and (max-width: 768px) {
    .boxBorder{
        padding: 10px;
    }
}