.logo {
  height: auto;
  width: 100px;
}

.topnavbar {
  background: #5a727a;
}

.topnavbar_row {
  margin: auto;
  color: white;
}

.appbar1__content{
  min-width: 300px !important;
}

.appbar1__content > a {
  color: white;
  margin-left: 2rem;
}

.footer_container {
  background: #5a727a;
  padding: 30px;
  color: white;
  margin-top: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  height: auto;
  /* position: fixed;
  bottom: 0; */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer_container_xs {
  background: #5a727a;
  padding: 30px;
  color: white;
  margin-top: 20px;
  height: 590px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer_header {
  font-weight: 700;
}

.footer_social {
  color: white;
}

.header_navs {
  /* display: flex; */
  margin-right: 3rem;
  justify-content: space-between;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .header_navs {
    width: 50%;
  }
}
